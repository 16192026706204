.campaign-app-layout {
  .header-main-action-button {
    margin-right: 8px;
  }

  .header-inventory-title {
    font-weight: bold;
    font-size: 18px;
  }

  .header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
.color-tag {
  display: flex;
  align-items: center;
}

.color-tag-icon {
  margin-right: 5px;
  margin-bottom: 2px;
  padding: 0;
  border-radius: 3px;
  flex-shrink: 0;
  border: 1px solid #d9d9d9;
}

.color-tag-border {
  border: 1px solid #d9d9d9;
  padding: 2px;
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}
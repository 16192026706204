.campaign-app-layout {
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }

  .scan-qr-bar-code-button {
    position: absolute;
    bottom: 100px;
    left: 30px;
    background-color: #108FEA;
    border-color: #108FEA;
    z-index: 2;
    width: 45px;
    height: 45px;
    border-radius: 48px;
  }
}
.inventory-table {
  width: 100%;

  .resource-image {
    text-align: center;
  }
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  position: fixed;
  bottom: 52px;
  width: 100%;
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {

  .inventory-table .ant-table-thead {
    display: none;
  }

  .inventory-table .ant-table-tbody > tr > td {
    display: block;
    width: 100%;
    border: none;
    padding: 4px 8px;
    box-sizing: border-box;
  }

  .inventory-table .ant-table-tbody > tr > td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    margin-bottom: 2px;
  }

  .inventory-table .ant-table-tbody > tr {
    border-bottom: 1px solid #e8e8e8;
  }

  .item-container {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #cdcccc;
  }

  .item-property:first-child {
    font-weight: bold;
  }

  .item-property {
    margin-bottom: 4px;
  }

  .item-image {
    width: 50px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .item-details {
    display: flex;
    flex-direction: column;
  }
}
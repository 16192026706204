.inventory-page {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    background-color: white;

    .header-container {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
    }

    .header-left {
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 18px;
      cursor: pointer;
      color: #108fe8;
    }

    .title {
      flex: 1;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      margin-left: 8px;
      margin-bottom: 0;
      color: #108fe8;
    }

    .photo-button {
      width: 100%;
      margin: 10px 0;
      font-size: 17px;
      background-color: #108fe8;
      border-color: #108fe8;
      padding: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .fields {
    .row {
      display: flex;
      justify-content: space-between;
      padding: 14px 16px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background: #f5f5f5;
      }

      .field {
        flex: 1;
      }

      .field-text {
        color: #898989;
      }

      .value {
        flex: 1;
        text-align: left;
      }
    }
  }
}
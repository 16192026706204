.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;

  .icon {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .text {
    font-size: 12px;
  }
}

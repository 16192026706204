.take-photo-page {
  .webcam-container {
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .webcam {
    width: 100%;
    height: auto;
    object-fit: scale-down;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 25px 30px;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  .cancel-text,
  .photos-text {
    font-size: 16px;
    cursor: pointer;
  }

  .capture-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border: 4px solid #108fe8;
    border-radius: 50%;
  }

  .capture-button {
    width: 68px;
    height: 68px;
    background-color: #108fe8;
    border-radius: 50%;
    cursor: pointer;
  }

  .switch-camera-button {
    background-color: #108fe8;
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #108fe8;
    }

    &:active {
      background-color: #108fe8;
    }

    .switch-camera-icon {
      font-size: 24px;
      color: white;
    }
  }
}
.add-new-resource-modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  .modal-text-size {
    font-size: 18px;
  }

  .add-new-resource-modal-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .add-new-resource-modal-content {
    .add-new-resource-modal-button {
      display: block;
      text-align: center;
      width: 100%;
    }
  }

  .ant-modal-body,
  .ant-modal-body .ant-btn {
    font-size: 18px;
  }

  .full-width {
    width: 100%;
  }

  .rounded-input {
    border-radius: 8px;
  }

  .padded-input {
    padding: 10px;
  }

  .large-font {
    font-size: 16px;
  }

  .spaced-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
// colors
$red: #f5222d;
$red-5: #ff4d4f;
$medium-light-gray: #aaa;
$default-grey: rgba(0, 0, 0, 0.65);
$grey: rgba(0, 0, 0, 0.45);
$black: rgba(0, 0, 0, 0.85);
$bg-grey: #fafafa;
$bg-dark-grey: rgba(0, 0, 0, 0.06);
$bg-blue: #e6f7ff;
$blue: #1890ff;
$bg-blue2: #baf0ff;
$bg-white: #ffffff;

.grey {
  color: $grey;
}

.center {
  text-align: center;
}

.right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.ant-table.ant-table-small .ant-table-tbody > tr.row-selected-for-edit td {
  background-color: $bg-blue;
}

.ant-table.ant-table-small .ant-table-tbody > tr.favorite td {
  font-weight: 700;

  &.project-column {
    font-weight: 400;
  }
}

.ant-table.ant-table-small .ant-table-tbody > tr.project-task td {
  font-style: italic;
}

.project-column .ant-tag {
  white-space: normal;
}

.one-line-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-in-panel {
  .ant-list-header {
    background-color: $bg-grey;
    font-weight: 600;
    padding-left: 20px;
  }

  .ant-row {
    width: 100%;
  }

  .ant-btn-link .anticon {
    color: $blue;
  }
}

.disabled {
  color: $grey;
  cursor: not-allowed;
}

.grey-modal {
  &.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: $grey;
  }
}

.ant-tag.ant-tag-has-color.project_tag,
.ant-tag.ant-tag-has-color.crew-project-tag,
.ant-tag.ant-tag-has-color.event-project-tag {
  color: $black;
  border: 1px solid #ffffff;
}

.ant-tag.project_tag {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px !important;
}

.text-nowrap {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.anticon.anticon-down,
.ant-form-item-control-input .anticon {
  color: inherit;
}

.project-status-badge-selector.project-status-badge {
  float: right;
  margin-top: 5px;
}

.ant-select-multiple .project-status-badge-selector.project-status-badge,
.ant-select-single .project-status-badge-selector.project-status-badge {
  display: none;
}

.time-zone-select-dropdown {
  width: 300px !important;
}

.color-icon-container {
  display: flex;
  align-items: center;
}

.color-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
}

.portfolio-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}
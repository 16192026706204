.code-is-unassigned-modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  .modal-text-size {
    font-size: 18px;
  }

  .code-is-unassigned-modal-text {
    text-align: center;
    margin-bottom: 20px;
  }

  .code-is-unassigned-modal-content {
    .code-is-unassigned-modal-gray-line {
      height: 1px;
      background-color: #f0f2f5;
      margin: 10px 0;
    }

    .code-is-unassigned-modal-button {
      display: block;
      text-align: center;
      width: 100%;
    }
  }

  .ant-modal-body,
  .ant-modal-body .ant-btn {
    font-size: 18px;
  }
}